import React, { useState } from 'react';
import classNames from 'classnames';
import mixpanel from 'mixpanel-browser';
import { func, node, object, shape, string } from 'prop-types';
import { Field as Fieldss, Form as FinalForm } from 'react-final-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from '../../../../util/reactIntl';
import Field, { hasDataInFields } from '../../Field';
import BlockContainer from '../BlockContainer';
import { Button, FieldSelect, FieldTextInput, IconClose, NamedLink } from '../../../../components';

import css from './BlockDefault.module.css';
// import TopbarSearchForm from '../../../../components/Topbar/TopbarSearchForm/TopbarSearchForm';

const FieldMedia = props => {
  const { className, media, sizes, options } = props;
  const hasMediaField = hasDataInFields([media], options);
  return hasMediaField ? (
    <div className={classNames(className, css.media)}>
      <Field data={media} sizes={sizes} options={options} />
    </div>
  ) : null;
};

const BlockDefaultComponent = props => {
  const {
    intl,
    blockId,
    className,
    rootClassName,
    mediaClassName,
    textClassName,
    ctaButtonClass,
    title,
    text,
    callToAction,
    media,
    responsiveImageSizes,
    options,
    sectionId,
    isCompany,
    listingConfig,
    isAuthenticated
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const hasTextComponentFields = hasDataInFields([title, text, callToAction], options);
  const { companyIndustry = [] } = listingConfig || {};
  const [showAuthPrompt, setShowAuthPrompt] = useState(false);
  const handleDiscoverTalentClick = () => {
    if (!isAuthenticated) {
      setShowAuthPrompt(true);
    }
  };

  return (
    <BlockContainer id={blockId} className={classes}>
      <FieldMedia
        media={media}
        sizes={responsiveImageSizes}
        className={classNames(mediaClassName, sectionId == 'fractional-professionals' && css.fractionMediaImage)}
        options={options}
      />
      {hasTextComponentFields ? (
        <div className={blockId == "hero-block" ? css.heroBlockBox : classNames(textClassName, css.text, sectionId == 'fractional-professionals' && css.fractionMedia)}>

          {sectionId == 'fractional-professionals' && callToAction?.href ? <a className={css.slideranchorTag} href={callToAction?.href} target='_blank'><Field data={title} options={options} /></a> : <Field data={title} options={options} />
          }
          <Field data={text} options={options} />
          {sectionId == 'fractional-professionals'
            ? null
            : <Field data={callToAction} className={ctaButtonClass} options={options} />}
          {blockId == "hero-block"
            ? <div className={css.filterWrapper}>
              <FinalForm
                initialValues={{ search: '' }}
                onSubmit={() => { }}
                render={({ form, values, handleSubmit }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <div className={css.homeSearchBox}>
                        <FieldSelect
                          id="sector"
                          name="sector"
                          onChange={(e) => form.change('search', (values.search ? (values.search + "&") : "") + (isCompany ? 'refinementList%5Bexperiences.inputValues.industry.label%5D%5B0%5D=' : 'refinementList%roles.relevantSkill.label%5D%5B0%5D=') + e)}
                          className={css.dropdownInput}
                        >
                          <option value={''}>Industry</option>
                          {companyIndustry.sort((a, b) => a.key.localeCompare(b.key)).map(l => <option key={l.key} value={l.value}>{l.label}</option>)}
                        </FieldSelect>
                        <FieldTextInput
                          type={'text'}
                          id={"keywords"}
                          name={"keywords"}
                          className={css.inputBox}
                          placeholder={intl.formatMessage({ id: "LandingPage.keywordTitle" })}
                        />
                        <div
                          onClick={() => {
                            mixpanel.track('Search', {
                              industryType: values.sector,
                              searchedTerm: values.keywords,
                              environment: (process.env && process.env.REACT_APP_ENV) || 'development',
                            });
                            handleDiscoverTalentClick();
                          }}
                          className={css.submitButtonWrapper}
                        >
                          {isAuthenticated ? (
                            <NamedLink
                              name="AlgoliaSearchPage"
                              className={css.submitButton}
                              to={{
                                search: values.keywords
                                  ? values.search
                                    ? values.search + '&query=' + values.keywords
                                    : 'query=' + values.keywords
                                  : values.search,
                              }}
                            >
                              {intl.formatMessage({ id: isCompany ? "LandingPage.searchButton" : "LandingPage.searchButtonRoles" })}
                            </NamedLink>
                          ) : (
                            <Button type="button" className={css.submitButton}>
                              {intl.formatMessage({ id: isCompany ? "LandingPage.searchButton" : "LandingPage.searchButtonRoles" })}
                            </Button>
                          )}
                        </div>
                      </div>
                    </form>
                  );
                }}
              />
            </div>
            : null}
          {showAuthPrompt && (
            <div className={css.authPrompt}>
              <div className={css.authPromptContent}>
                <span className={css.closeButton} onClick={() => setShowAuthPrompt(false)}><IconClose /></span>
                <p>  {intl.formatMessage({ id: "LandingPage.loginMessage" })}</p>
                <NamedLink name="LoginPage" className={css.authLink}>
                  {intl.formatMessage({ id: "LandingPage.logIn" })}
                </NamedLink>&nbsp;
                <span>or</span>&nbsp;
                <NamedLink name="SignupPage" className={css.authLink}>
                  {intl.formatMessage({ id: "LandingPage.signUp" })}
                </NamedLink>
              </div>
            </div>
          )}
        </div>
      ) : null}
    </BlockContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

BlockDefaultComponent.defaultProps = {
  className: null,
  rootClassName: null,
  mediaClassName: null,
  textClassName: null,
  ctaButtonClass: null,
  title: null,
  text: null,
  callToAction: null,
  media: null,
  responsiveImageSizes: null,
  options: null,
};

BlockDefaultComponent.propTypes = {
  blockId: string.isRequired,
  className: string,
  rootClassName: string,
  mediaClassName: string,
  textClassName: string,
  ctaButtonClass: string,
  title: object,
  text: object,
  callToAction: object,
  media: object,
  responsiveImageSizes: string,
  options: propTypeOption,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.auth;
  return {
    isAuthenticated,
  };
};

const BlockDefault = compose(
  connect(
    mapStateToProps,

  ),

)(BlockDefaultComponent);

export default BlockDefault;
